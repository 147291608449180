import { t, Trans } from '@lingui/macro';
import { useRouter } from 'next/router';
import type { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import Image from 'next/image';
import {
  BlogList,
  Button,
  Container,
  CTA,
  DownloadButton,
  FAQ,
  Feature,
  FeatureEmbed,
  FeatureUseCaseList,
  PageATF,
  PageTitle,
  Quotes,
  TestimonialsSlider,
  TextRotate,
} from '@/components';
import QuoteMarianoImage from '@/images/testimonials/quote-mariano-sap.jpg';
import QuoteTuyenImage from '@/images/testimonials/quote-tuyen-steelseries.jpg';
import QuoteJesperImage from '@/images/testimonials/quote-jesper-albatros.jpg';
import QuoteXSplitImage from '@/images/testimonials/quote-andreas-hoye-xsplit.jpg';
import QuoteIntelImage from '@/images/testimonials/quote-roger-intel.jpeg';
import QuoteLegendImage from '@/images/testimonials/quote-sean-fee-legend.jpg';
import SalesAndMarketingImage from '@/images/use-cases/sales-and-marketing.jpg';
import EducationImage from '@/images/use-cases/education.jpg';
import CustomerSupportImage from '@/images/use-cases/customer-support.jpg';
import ShowingImage from '@/images/homepage/showing.png';
import InsightsImage from '@/images/homepage/insights.png';
import CollaborateImage from '@/images/homepage/collaborate.png';
import SecureImage from '@/images/homepage/secure.png';
import QuickImage from '@/images/homepage/quick.png';
import DemoPoster from '@/images/posters/watch-a-demo.jpg';
import { useOG, useAnalytics, useI18n } from '@/hooks';
import { withLocale } from '@/locales';
import { Product } from '@/enums';
import { queries, sanity } from '@/interfaces';
import type { BlogArticle } from '@/types';

const featuredArticleList: string[] = [
  'presenter-patch-notes-1',
  'video-prospecting-sales',
  'boost-sales-accessibility-on-demand-demo-videos',
];

const Home: NextPage<{ featuredArticles: BlogArticle[] }> = ({ featuredArticles }) => {
  const { openGraph } = useOG(t`Level up your communication with video`);
  const { track } = useAnalytics();
  const { locale } = useRouter();
  const { pick } = useI18n();

  const demoVideo = pick('https://cloud.videocom.com/embed/fi_01GTXHR5EM976200XQ8KZ6Z063', {
    fr: 'https://cloud.videocom.com/embed/fi_01GTXHR5EM976200XQ8KZ6Z063',
  });

  return (
    <>
      <NextSeo
        title={t`Level up your communication with video`}
        description={t`VideoCom empowers you to create and share screen captures or high quality video presentations with ease.`}
        openGraph={{ ...openGraph }}
      />
      <PageATF
        title={
          <TextRotate
            items={[t`presentations`, t`pitches`, t`demos`, t`how to’s`, t`training`, t`tutorials`, t`messages`, t`reports`]}
            speed={3000}
          >{t`Now anyone can make video based`}</TextRotate>
        }
        titleText={t`Now anyone can make video based pitches`}
        tagline={t`No editing. No expensive equipment. No expertise required.`}
        switchTitle
        button={
          <Button
            href={`https://cloud.videocom.com/${locale}/signup`}
            large
            secondary
            self
            onClick={() => {
              track('vcw.get_started.click', {
                data: { origin: 'cta' },
              });
            }}
          >{t`Get Started for FREE`}</Button>
        }
        image={
          <>
            <div className="flex w-full h-auto aspect-video px-4">
              <video className="block w-full h-full" playsInline autoPlay muted loop>
                <source src="https://storage.videocom.com/site/sales-atf.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="rounded-full overflow-hidden absolute top-[20.5%] right-[1%] w-[24.8%] border-4 border-white ring-4 ring-primary-500 aspect-square z-10">
              <video className="block w-full h-full" playsInline autoPlay muted loop>
                <source src="https://storage.videocom.com/site/webcam.mp4" type="video/mp4" />
              </video>
            </div>
          </>
        }
        blueBackground
        noVideoCom
      />
      <PageTitle as="h2" title={t`Let's hear what people have to say about us`} noPaddingBottom switchTitle />
      <Quotes
        items={[
          {
            name: 'Roger Chandler',
            company: t`Vice President & General Manager, Creator and Workstation Solutions at Intel`,
            quote: t`I love the effect of immersing the web camera into the presentation. The AI developed for this function was optimized in collaboration with Intel, so I am very excited to see the feature being rolled out.`,
            avatarImage: QuoteIntelImage,
          },
          {
            name: 'Jesper Dichmann',
            company: t`CMO - Albatros Travel`,
            quote: t`VideoCom gives us an easy way to create and publish video content promoting our various destinations. Support for both Mac and PC is really helpful.`,
            avatarImage: QuoteJesperImage,
          },
          {
            name: 'Tuyen Pham',
            company: t`Director - SteelSeries`,
            quote: t`I am really intrigued about VideoCom's concepts around interactive video. The ability for viewers of the same video to potentially watch different sections based on their personal interaction with the content has incredible potential.`,
            avatarImage: QuoteTuyenImage,
          },
        ]}
      />
      <FeatureEmbed
        as="h2"
        title={t`Watch a demo`}
        embed={{
          url: demoVideo,
          poster: DemoPoster,
        }}
      />
      <PageTitle as="h2" title={t`Record`} noPaddingBottom switchTitle>
        <>{t`Whether it's a quick screen capture or a super charged presentation recording, VideoCom makes it a breeze.`}</>
      </PageTitle>
      <Feature
        as="h3"
        title={t`Showing over telling`}
        side={
          <Image
            src={ShowingImage}
            width={502}
            height={ShowingImage.height}
            alt={t`Two video players with floating circle portraits and speech bubbles.`}
          />
        }
        actions={
          <>
            <DownloadButton product={Product.Capture} large>{t`Download Capture`}</DownloadButton>
            <Button href="/products/capture" flat large>{t`Learn about Capture`}</Button>
          </>
        }
      >{t`Record your screen with voice over and annotations to save time and avoid misunderstandings.`}</Feature>
      <Feature
        as="h3"
        title={t`Personalize your presentations`}
        side={
          <Image
            src={QuickImage}
            width={500}
            height={QuickImage.height}
            alt={t`A presentation showing graphs annotations and a circle shaped webcam on top.`}
          />
        }
        actions={
          <>
            <DownloadButton product={Product.Presenter} large>{t`Download Presenter`}</DownloadButton>
            <Button href="/products/presenter" flat large>{t`Learn about Presenter`}</Button>
          </>
        }
        reverse
      >{t`Personalize your PowerPoint, Google Slide or PDF presentations by adding yourself with a webcam. Make your presentations interactive by adding calls-to-actions, linking to other videos and more.`}</Feature>
      <PageTitle as="h2" title={t`Share`} switchTitle minimalPadding>
        <>{t`Easy, painless and secure.`}</>
      </PageTitle>
      <Feature
        as="h3"
        title={t`Share securely`}
        side={
          <Image
            src={SecureImage}
            width={SecureImage.width}
            height={SecureImage.height}
            alt={t`Illustration of a piece of media with an access form and a lock icon.`}
          />
        }
        actions={
          <Button
            href={`https://cloud.videocom.com/${locale}/signup`}
            large
            self
            onClick={() => {
              track('vcw.get_started.click', {
                data: { origin: 'home.secure' },
              });
            }}
          >{t`Get Started`}</Button>
        }
      >{t`By default your media is stored in our secure cloud and instantly available as a share link. Sharing long-form videos has never been easier! You can set up permissions on the fly and even collect detailed insights.`}</Feature>
      <Feature
        as="h3"
        title={t`Collaborate with your team.`}
        side={
          <Image
            src={CollaborateImage}
            width={CollaborateImage.width}
            height={CollaborateImage.height}
            alt={t`Multiple portraits of users with chat bubble and graphs.`}
          />
        }
        actions={
          <Button
            href={`https://cloud.videocom.com/${locale}/signup`}
            large
            self
            onClick={() => {
              track('vcw.get_started.click', {
                data: { origin: 'home.collaborate' },
              });
            }}
          >{t`Get Started`}</Button>
        }
        reverse
      >{t`Invite members to access, share, provide feedback and collaborate using team media. Easily segregate your personal files from the ones you share.`}</Feature>
      <Feature
        as="h3"
        title={t`Advanced insights at your fingertips`}
        side={
          <Image
            src={InsightsImage}
            width={InsightsImage.width}
            height={InsightsImage.height}
            alt={t`Illustrative version of a media page with floating stats on top.`}
          />
        }
        actions={
          <Button
            href={`https://cloud.videocom.com/${locale}/signup`}
            large
            self
            onClick={() => {
              track('vcw.get_started.click', {
                data: { origin: 'home.insights' },
              });
            }}
          >{t`Get Started`}</Button>
        }
      >{t`Understand how your media is consumed with simple but powerful dashboards. Explore insights on a per file basis or see the bigger picture for all files from your team in a workspace.`}</Feature>
      <PageTitle as="h2" title={t`Communicate`} switchTitle>
        <>{t`Capture and present your product, ideas, problems, solutions and other information, and turn it into meaningful content.`}</>
      </PageTitle>
      <FeatureUseCaseList
        items={[
          {
            name: t`Sales & Marketing`,
            title: t`The Future of Customer Engagement, One Video at a Time`,
            content: (
              <Trans>
                <p>VideoCom allows you to create live and on-demand demos that save time and close deals faster.</p>
                <p>
                  Empower your customers by creating a personalized experience that they can enjoy on their own, at their most convenient
                  time.
                </p>
                <p>Add CTA&apos;s and track viewer engagement to measure your demo success. Stand out and work more efficiently.</p>
              </Trans>
            ),
            image: SalesAndMarketingImage,
            imageAlt: t`Person sitting down, talking in to their laptop`,
            actions: <Button href="/sales" large self bordered>{t`Read More on Sales`}</Button>,
          },
          {
            name: t`Education`,
            title: t`Create Online Training Videos with VideoCom Cloud`,
            content: (
              <Trans>
                <p>
                  Teach more effectively and get action from your audience: VideoCom Cloud lets you create video e-learning content in
                  minutes.
                </p>
                Now you can deal with the prohibitive time, budget, and quality constraints that come up when creating video training for
                your team! Use VideoCom Presenter to import your existing PowerPoint deck or design your own training flowchart directly in
                the software. Then add text, pictures, animations and annotations to turn your outline into an engaging video presentation.
                <p>
                  Include online links within the video to provide additional resources or send viewers to other videos within the course.
                </p>
              </Trans>
            ),
            image: EducationImage,
            imageAlt: t`Student on their laptop, with pencil and notepad.`,
            actions: <Button href="/education" large self bordered>{t`Read More on Education`}</Button>,
          },
          {
            name: t`Customer Support`,
            title: t`Build A Knowledgebase With VideoCom`,
            content: (
              <Trans>
                <p>Use VideoCom to generate a knowledge base, reduce calls to customer support, and increase customer satisfaction.</p>
                <p>
                  Reduce your support costs by recording how-to tutorials and integrating them into your Knowledge Base. Add relevant
                  commenting features to facilitate Q&A.
                </p>
                <p>Increase customer satisfaction by showing users exactly what they need to do to accomplish their goal.</p>
              </Trans>
            ),
            image: CustomerSupportImage,
            imageAlt: t`A customer support person at their desk, wearing a headset with a microphone.`,
            actions: <Button href="/use-cases/customer-support" large self bordered>{t`Read More on Customer Support`}</Button>,
          },
        ]}
      />
      <PageTitle as="h2" id="product-section" title={t`Thoughts from the industry`} noPaddingBottom switchTitle />
      <TestimonialsSlider
        items={[
          {
            author: 'Public News Time',
            quote: t`VideoCom is all about helping you record perfect presentations without any technical expertise. With innovative tools, you can turn static visuals into dynamic video that better gets your point across, whether you’re making an instructional video or analyzing the quarterly numbers.`,
          },
          {
            author: 'PC World',
            quote: t`In the world of remote work, we’ve all gotten pretty used to video calls. But we’re not used to making presentations or conveying complex information through video. That’s where VideoCom comes in. This intuitive program helps you markup and annotate videos to make more compelling presentations, better display information, improve training materials, and much more.`,
          },
          {
            author: 'IFL Science',
            quote: t`So, you’re not exactly great with creating stunning video content, but that’s not a problem when you have VideoCom Apps Pro. This innovative tool enables you to create beautiful professional-looking video presentations with very little effort or know-how.`,
          },
          {
            author: 'PCMag',
            quote: t`VideoCom puts professional-grade video editing and hosting tools in the hands of content creators and advertisers. Turn PowerPoint or Google Slides into engaging videos, or create your own presentations from scratch.`,
          },
          {
            author: 'Entrepreneur',
            quote: t`VideoCom gives you outstanding tools to create instructional videos, presentations, and more, with intuitive tools to enhance your communication with remote employees and collaborators.`,
          },
        ]}
      />
      <FAQ
        className="my-24"
        title={t`Frequently Asked Questions`}
        items={[
          {
            question: t`What is VideoCom Cloud?`,
            answer: t`Cloud is a fundamental part of the VideoCom ecosystem, where you can save media you’ve created with Presenter and Capture to your own personal or shared workspace. This makes it easier than ever to save, organize, and share your files with everyone without the need for a separate file sharing service. A unique share link is generated for each file, so anyone can view your media without difficulty.          `,
          },
          {
            question: t`Is VideoCom Presenter the same thing as PowerPoint and Google Slides?`,
            answer: t`VideoCom Presenter is a powerful app designed to level up your existing PowerPoint and Google Slides presentations. Add annotations, webcams, and rich-media files like videos and images with just a few clicks! Its seamless integration with the VideoCom Cloud allows you to share a link to your newly-recorded video presentation as soon as you’re done.`,
          },
          {
            question: t`Can I replace Microsoft Teams, Zoom and Google Meet with VideoCom?`,
            answer: t`VideoCom is great for delivering presentations that look professional and run smoothly, but it's not meant to replace your regular video conferencing tools. For example, you can use VideoCom Presenter to display your slides on a monitor, and then you can use the screenshare feature of Microsoft Teams, Zoom, Google Meet, or another video conferencing tool to show them to your audience!          `,
          },
          {
            question: t`Are VideoCom applications available on my Mac?`,
            answer: t`Yes! Thanks to the growing interest in VideoCom creator apps among Mac users as well as companies, Presenter and Capture can now be downloaded and used on Mac operating systems. Mac users will require at least a macOS 10.11 (El Capitan) version with a 5th Generation Core i5 CPU or equivalent to run the VideoCom creator apps.`,
          },
          {
            question: t`How is VideoCom Capture different from Windows Snipping Tool or MacOS Screenshot?`,
            answer: t`VideoCom Capture is an all-in-one solution for sharing your screen, and it comes with a lot of built-in features like the ability to add annotations or record with your microphone to draw better attention to the most important parts of your content. What’s more, your screenshots and recordings can be saved in the VideoCom Cloud, and you can send them to friends and colleagues via a secure shared link!`,
          },
          {
            question: t`Can I try VideoCom for free?`,
            answer: t`Absolutely! You can sign up for a free account to enjoy VideoCom’s basic plan features at no cost. This allows you to upload up to 25 files or a total of 1GB worth of VideoCom cloud space. We also have Pro and Enterprise plans for those who want to unlock the full power of VideoCom with unlimited media file storage and more.`,
          },
        ]}
      />
      <Quotes
        items={[
          {
            name: 'Mariano Kristensen',
            company: t`Sales Manager - SAP Innovation Platforms & Technology`,
            quote: t`It's really great that you can use VideoCom for both presentation recordings and live presentations in meetings.`,
            avatarImage: QuoteMarianoImage,
          },
          {
            name: 'Andreas Hoye',
            company: t`CEO of XSplit`,
            quote: t`Using VideoCom Presenter to prepare and share our monthly company updates with the team and our shareholders has saved me a tremendous amount of time.`,
            avatarImage: QuoteXSplitImage,
          },
          {
            name: 'Sean Fee',
            company: t`CEO of Legend`,
            quote: t`This fantastic service has made it incredibly easy for me to make personalized video recordings and presentations.`,
            avatarImage: QuoteLegendImage,
          },
        ]}
      />
      {featuredArticles.length > 0 && (
        <Container className="mb-24">
          <PageTitle as="h2" id="featured-article-section" title={t`From the blog`} switchTitle />
          <BlogList articles={featuredArticles} />
        </Container>
      )}
      <CTA />
    </>
  );
};

export const getStaticProps = withLocale('static', async ({ locale, defaultLocale = 'en' }) => {
  if (!locale) {
    locale = defaultLocale;
  }

  const groqFeaturedArticleList = featuredArticleList.map(article => `"${article}"`).join(', ');

  const featuredArticles = await sanity.fetch<BlogArticle[]>(
    `*[_type == "article" && slug.current in [${groqFeaturedArticleList}]]${queries.blogArticle()} | order(published_at desc)`,
    { locale },
  );

  return {
    props: { featuredArticles },
  };
});

export default Home;
